nav .navbar-nav, nav ul {
    list-style: none;
    list-style-type: none;
    display: block;
}
.nav-item {
    display: inline-flex;
    padding: 6px 0;
    font-size: 18px;
    font-size: 1.2rem;
    margin-right: 90px;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
}
.nav-item, .nav-item a, .nav-item:hover, .nav-item a:hover {
    color: cssvar(primary-accent-color);
    text-decoration: none;
}
.nav-item.active {
    border-bottom: solid 1px cssvar(primary-color);
}
.nav-item.active, .nav-item.active a, .nav-item.active:hover, .nav-item.active a:hover {
    color: cssvar(primary-color);
    font-weight: bold;
}
.open-menu {
    display: none;
}
.close-menu {
    display: none;
}

@media screen and (max-width: 600px) {
    nav {
        position: fixed;
        width: 100%; 
        height: 100%; 
        top: 0px;
        bottom: 0px;
        z-index: 2; 
        transition: 1s;
    }
    nav .navbar-nav, nav ul {
        background-color: cssvar(secondary-accent-color);
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding-top: 10px;
    }
    .nav-item {
        display: block;
        font-size: inherit;
        margin: auto;
    }
    .nav-item.active {
        border-bottom: none;
    }
    .close-menu {
        display: inherit;
        position: absolute;
        left: 0px;
        top: 0px;
        padding: 5px 10px;
        * {
            color: cssvar(secondary-color);
        }
    }
    .open-menu {
        display: inherit;
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 5px 10px;
        * {
            color: cssvar(primary-font-color);
        }
    }
    .menu-open {
        left: 0px;
        transition: 1s;
    }
    .menu-closed {
        left: -800px;
        transition: 1s;
    }
}
