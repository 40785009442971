/**
 * Retrieve a css variable value with prefix
 *
 * Usage
 *
 * .selector {
 *   color: cssva(primary-color);
 * }
 *
 * Will result in
 *
 * .selector {
 *    color: var(--prefix-primary-color);
 * }
 */
 @function cssvar($name, $prefix: kay) {
    @return var(--#{$prefix}-#{$name});
}
