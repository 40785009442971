/* You can add global styles to this file, and also import other style files */
@import './styles/main.scss';

html, body {
    min-height: 100vh;
    height: auto;
    margin: 0;
  }
app-root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
