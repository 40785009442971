@import './config/_cssvars';
@import './config/_cssvar';

@import './modules/_nav';
@import './modules/_buttons';


@include cssvars((
    primary-color: white,
    primary-accent-color: #00afb9,
    secondary-color: #2d0152,
    secondary-accent-color: #ffd0cc,
    tertiary-color: #a10532,

    primary-font-color: black,
    secondary-font-color: grey,
    alternate-font-color: white,
    base-font-size: 65.5%,
    font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif}
));
@font-face {
    font-family: Paprika;
    src: url(../assets/fonts/Paprika/Paprika-Regular.ttf) format("opentype");
}
@font-face {
    font-family: 'Dreaming Outloud';
    src: url(../assets/fonts/Dreaming-Outloud/dreaming-outloud-script.otf) format("opentype");
}
h1 {
    color: cssvar(secondary-color);
}
a {
    color: cssvar(secondary-color);
}
a:hover {
    color: cssvar(secondary-accent-color);
}