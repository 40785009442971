
.btn-primary {
    background-color: cssvar(primary-color);
    border-color: cssvar(primary-color);
    filter: brightness(1.1);
}
.btn-primary:hover {
    background-color: cssvar(primary-accent-color);
    border-color: cssvar(primary-accent-color);
    filter: brightness(1);
    transition: 10ms;
}
